<template>
    <div>
        <!-- 정보입력 -->
        <!-- 정보입력: 일반 -->
        <p class="form__caption">* 필수입력 항목입니다.</p>
        <div class="form--primary">
            <form-row tit="아이디" pointer>
                <v-text-field v-model="user.username" @input="input" type="text" name="username" outlined hide-details />
            </form-row>
            <form-row tit="비밀번호" pointer>
                <v-text-field v-model="user.password" @input="input" type="password" name="password" outlined hint="*(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)" persistent-hint/>
            </form-row>
            <form-row tit="비밀번호 확인" pointer>
                <v-text-field v-model="user.passwordConfirm" @input="input" type="password" name="password2" outlined hide-details/>
            </form-row>
            <form-row tit="이름" pointer>
                <v-text-field v-model="user.name" @input="input" type="text" name="name" outlined hide-details/>
            </form-row>
            <form-row tit="연락처" pointer>
                <v-row class="row--x-small" align="center">
                    <v-col>
                        <v-text-field v-model.number="user.phone1" type="number" name="phone1" maxlength="4" outlined hide-details />
                    </v-col>
                    <v-col cols="auto">-</v-col>
                    <v-col>
                        <v-text-field v-model.number="user.phone2" type="number" name="phone2" maxlength="4" outlined hide-details />
                    </v-col>
                    <v-col cols="auto">-</v-col>
                    <v-col>
                        <v-text-field v-model.number="user.phone3" type="number" name="phone3" maxlength="4" outlined hide-details />
                    </v-col>
                </v-row>
            </form-row>
            <form-row tit="이메일" pointer>
                <v-row class="row--x-small" align="center">
                    <v-col cols="6" md="4">
                        <v-text-field v-model="user.email1" type="text" name="email1" outlined hide-details />
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-text-field v-model="user.email2" type="text" name="email2" prefix="@" outlined hide-details />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select v-model="user.email3" v-bind="$attrs" persistent-hint :items="prependEmails" item-text="text" item-value="value" outlined hide-details @change="user.email2 = user.email3"/>
                    </v-col>
                </v-row>
            </form-row>
            <form-row tit="기관명" pointer>
                <v-text-field v-model="user.companyName" @input="input" type="text" name="companyName" outlined hide-details/>
            </form-row>
            <form-row tit="직급" pointer>
                <v-text-field v-model="user.occupation" @input="input" type="text" name="occupation" outlined hide-details/>
            </form-row>
        </div>
        <!-- <div class="table-style table-style--default">
            <div class="table-style__row">
                <div class="table-style__th">아이디 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.username" @input="input" type="text" name="username" outlined hide-details class="w-100 mw-lg-500px" />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">비밀번호 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.password" @input="input" type="password" name="password" outlined hide-details class="w-100 mw-lg-500px" />
                    <small class="text-grey text-grey--right">(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)</small>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">비밀번호 확인 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.passwordConfirm" @input="input" type="password" name="password2" outlined hide-details class="w-100 mw-lg-500px" />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">이름 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.name" @input="input" type="text" name="name" outlined hide-details class="w-100 mw-lg-500px" />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">연락처 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col>
                                <v-text-field v-model="user.phone1" type="tel" name="phone1" maxlength="4" outlined hide-details class="w-100" />
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                <v-text-field v-model="user.phone2" type="tel" name="phone2" maxlength="4" outlined hide-details class="w-100" />
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                <v-text-field v-model="user.phone3" type="tel" name="phone3" maxlength="4" outlined hide-details class="w-100" />
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">이메일 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col cols="6" md="4">
                                <v-text-field v-model="user.email1" type="text" name="email1" outlined hide-details class="w-100" />
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field v-model="user.email2" type="text" name="email2" prefix="@" outlined hide-details class="w-100" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <select v-model="user.email3" @change="user.email2 = user.email3" class="select w-100">
                                    <option :value="null">직접입력</option>
                                    <option :value="`naver.com`">naver.com</option>
                                    <option :value="`daum.net`">daum.net</option>
                                    <option :value="`google.com`">google.com</option>
                                </select>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">직급</div>
                <div class="table-style__td">
                    <v-text-field v-model="user.occupation" @input="input" type="text" name="occupation" outlined hide-details class="w-100 mw-lg-500px" />
                </div>
            </div>
        </div> -->

        <div class="v-btn--group">
            <v-btn outlined large color="grey-d" class="min-w-140px min-w-lg-160px" @click="cancel()"><span class="grey-6--text">취소</span></v-btn>
            <v-btn large color="primary" class="min-w-140px min-w-lg-160px" @click="submit()">회원가입</v-btn>
        </div>

    </div>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import FormRow from "@/components/client/sub/form-row.vue";

export default {
    components: {
        DaumPostcode,
        KcpCert,
        FormRow,
    },
    props: ["value"],
    data() {
        return {
            user: this.$props.value || {},
            prependEmails: [
                { text: ":: 직접입력 ::", value: "" },
                { text: "naver.com", value: "naver.com" },
                { text: "gmail.com", value: "gmail.com" },
                { text: "daum.net", value: "daum.net" },
                { text: "hanmail.net", value: "hanmail.net" },
            ],
        };
    },
    watch: {
        "user.email"() {
            this.$emit("input", this.user);
        },
        "user.email1"() {
            this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}` : null);
        },
        "user.email2"() {
            this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}` : null);
        },

        "user.phone"() {
            this.$emit("input", this.user);
        },
        "user.phone1"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
        "user.phone2"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
        "user.phone3"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
    },
    methods: {
        input() {
            this.$emit("input", this.user);
        },
        cancel() {
            this.$router.go(-1);
        },

        submit() {
            if (this.validate()) this.$emit("submit", this.user);
        },

        verify(payload) {
            this.user._certification = payload._certification;
            var [phone1, phone2, phone3] = payload.phone?.phoneNumberFormat()?.split("-");
            this.user.phone1 = phone1;
            this.user.phone2 = phone2;
            this.user.phone3 = phone3;
        },

        validate() {
            try {
                if (!this.user.username) throw new Error("아이디를 입력해주세요.");
                if (!this.user.password) throw new Error("비밀번호를 입력해주세요.");

                if (!this.checkPassword(this.user.password)) return false;
                if (!this.user.passwordConfirm) throw new Error("비밀번호 확인을 입력해주세요.");
                if (this.user.password != this.user.passwordConfirm) throw new Error("비밀번호를 확인해주세요");

                if (!this.user.name) throw new Error("이름을 입력해주세요.");

                if (!this.user.phone1 || !this.user.phone2 || !this.user.phone3) {
                    if (!this.user._certification) {
                        this.certify();
                        return false;
                    }
                }

                if (!this.user.email1 || !this.user.email2) throw new Error("이메일을 입력해주세요.");

                if (!this.user.companyName) throw new Error("기관명을 입력해주세요.");
                if (!this.user.occupation) throw new Error("직급을 입력해주세요.");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("비밀번호에 특수문자가 포함되어있어야합니다.");
            if (!checkEng.test(password)) throw new Error("비밀번호에 영문이 포함되어있어야합니다.");
            if (!checkNum.test(password)) throw new Error("비밀번호에 숫자가 포함되어있어야합니다.");

            if (password.length < 8 || 16 < password.length) throw new Error("비밀번호는 8~16자까지입니다.");

            return true;
        },
    },
};
</script>
