var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "form__caption"
  }, [_vm._v("* 필수입력 항목입니다.")]), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "아이디",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "username",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "비밀번호",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "name": "password",
      "outlined": "",
      "hint": "*(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)",
      "persistent-hint": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "비밀번호 확인",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "name": "password2",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.user, "passwordConfirm", $$v);
      },
      expression: "user.passwordConfirm"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "이름",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "name",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "연락처",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "name": "phone1",
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone1", _vm._n($$v));
      },
      expression: "user.phone1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "name": "phone2",
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone2", _vm._n($$v));
      },
      expression: "user.phone2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "name": "phone3",
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone3,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone3", _vm._n($$v));
      },
      expression: "user.phone3"
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "이메일",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "email1",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email1", $$v);
      },
      expression: "user.email1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "email2",
      "prefix": "@",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email2", $$v);
      },
      expression: "user.email2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "persistent-hint": "",
      "items": _vm.prependEmails,
      "item-text": "text",
      "item-value": "value",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        _vm.user.email2 = _vm.user.email3;
      }
    },
    model: {
      value: _vm.user.email3,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email3", $$v);
      },
      expression: "user.email3"
    }
  }, 'v-select', _vm.$attrs, false))], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "기관명",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "companyName",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.user, "companyName", $$v);
      },
      expression: "user.companyName"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "직급",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "occupation",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.occupation,
      callback: function ($$v) {
        _vm.$set(_vm.user, "occupation", $$v);
      },
      expression: "user.occupation"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "outlined": "",
      "large": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("취소")])]), _c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("회원가입")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }